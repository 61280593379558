import React from 'react'
import { REWORK } from './rework_desc';

class ProxyControlRework extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bit: "",
      desc: "",
      options: "",
    };

    this.setBit = this.setBit.bind(this);
    this.setDesc = this.setDesc.bind(this);
  }

  componentDidMount() {
    let options = [];
    for (let index = 1; index <= 32; index++) {
      options.push(<option key={"bit_" + index}>{index}</option>);
    }

    this.setState({options: options});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount() {
  }

  setBit(e) {
    if (isNaN(e.target.value) || e.target.value < 1 || e.target.value > 32) {
      this.setState({bit: "", desc: ""})
      return false;
    }

    this.setState({bit: e.target.value, desc: REWORK[e.target.value - 1]})
  }

  setDesc(){
    console.log("Add desc")

  }

  render() {
    return (
      <div className="Control">
        <div className="Fields">
          <div className="Field">Select Rework:</div>
          <div className="Value">
            <input
              type="text"
              id="bit_input"
              list="bits"
              value={this.state.bit}
              onClick={()=>this.setState({bit: "", desc: ""})}
              onChange={this.setBit}
            />
            <datalist id="bits">{this.state.options}</datalist>
          </div>
        </div>
        <div className="Fields">
          <div className="Field"/>
          <div className="Value3">
              <textarea
                id="desc"
                name="desc"
                rows="4"
                cols="125"
                value={this.state.desc}
                onChange={(e)=>this.setState({desc: e.target.value})}
                />
          </div>
        </div>
        <div className="Field"/>
        <div className="Value3">
          <button className="ResetMCU disabled" disabled onClick={this.setDesc}>Set Description</button>
        </div>
       </div>
    );
  }
}

export default ProxyControlRework;
