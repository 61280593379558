
export function fetchAPI(url, init) {
  return new Promise((resolve, reject) => {
    fetch(url, init)
      .then(response => {
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

/*
Commands:
Epoch - show/set time
Dev_Id - show/set SN/ID
Version - show FW version (mcu fw ver)
Commit - show FW commit_id (mcu cid)
DAC - show/set OCXO DAC value (dac word)
ADC - show OCXO ADC value
Temp - show Temperature (C) (Temp)
Pressure - show Pressure (hPa) (Pressure)
Motion - show XYZ Motion
SOM - show/set(0,1)/reset(2) SOM Power
http://172.16.2.67:18080/mcu?port=/dev/ttyPS0&cmd=help
MCU SW Ver: 204592b; Dev_Id: 0x0
http://172.16.2.183:18080/mcu?port=/dev/ttyPS0&cmd=pre
*/

export function fetchReader(readerId, command) {
  console.log("fetchReader " + readerId, command)
  let url = "http://" + readerId + ":18080/mcu?port=/dev/ttyPS0&cmd=" + command;
  let init = { method: 'get'}

  return fetchAPI(url, init)
      .then(response => response.text());
}

/*
http://172.16.2.217:18080/info?port=/dev/ttyPS0

{ "SOM": { "Zynq T": "31.4984", "OS": "Ubuntu 18.04.5 LTS\n" },
"RFFE": { "Board ver": "n/a" }, "Carrier": { "MAC addr": "00:04:4b:00:68:94", "Pwr Carrier": "n/a",
"Board rework": "n/a", "Board ver": "n/a", "MCU CID": " 0x77\n\n", "MCU FW ver": "42af6b5\n\n",
 "Pwr 9.5": "n/a", "Pwr Module": "n/a", "Temp": "Temperature=29.50Deg C\n\n",
 "Pressure": "Pressure=1003hPa\n\n", "Accel": "Motion=0x4200ff, X=-0.02g, Y= 0.00g, Z= 1.03g\n\n",
 "DAC word": "DAC=0xa680 Voltage=1.626V\n\n" }, "Proxy ver": "4c2e152",
 "IP": "172.16.2.217", "Hostname": "sdrj5"}
*/
export function fetchReaderJson(readerId, command) {
  let url = "http://" + readerId + ":18080/" + command + "?port=/dev/ttyPS0"
  let init = { method: 'get'}

  return fetchAPI(url, init)
      .then(response => response.json());
}

/*
IP:  172.16.2.67
To update dev_id you need first to "unlock" the storage
Aladdin spell is: 0xffffffff
Example:
Browser: http://sdrj10:18080/mcu?port=/dev/ttyPS0&cmd=dev_id%200xFFFFFFFF
       (case indepentent)
 2.  wget:
       wget -q -S -O - 'http://sdrj10:18080/mcu?port=/dev/ttyPS0&cmd=dev_id 0xffffffff'
 3. After you opened the "cave" , repeat the same command with a "real" value:
      wget -q -S -O - 'http://sdrj10:18080/mcu?port=/dev/ttyPS0&cmd=dev_id 0xabcd'

*/
export function unlockReader(readerId, command) {
  console.log("unlockReader " + readerId, command)
  let url = "http://" + readerId + ":18080/mcu?port=/dev/ttyPS0&cmd=" + command + "%200xFFFFFFFF";
  let init = { method: 'get'}

  return fetchAPI(url, init)
      .then(response => response.text());
}

export function updateReaderProp(readerId, command, value) {
  console.log("updateReader " + readerId + " " + command + " " + value)
  let url = "http://" + readerId + ":18080/mcu?port=/dev/ttyPS0&cmd=" + command + "%20" + value;
  let init = { method: 'get'}

  return fetchAPI(url, init)
      .then(response => response.text());
}

export function burnReaderProp(readerId, value) {
  console.log("burnReader " + readerId + " " + value)
  let url = "http://" + readerId + ":18080/mcu_update?port=/dev/ttyPS0&id=" + value;
  let init = { method: 'get'}

  return fetchAPI(url, init)
      .then(response => response.text());
}

export function getBurnReaderPropUpdate(readerId) {
  console.log("burnReaderUpdate " + readerId)
  let url = "http://" + readerId + ":18080/mcu_status?port=/dev/ttyPS0";
  let init = { method: 'get'}

  return fetchAPI(url, init)
      .then(response => response.text());

}

export function postReaderJson(readerId, command, body) {
  let url = "http://" + readerId + ":18080/" + command;
  let init = {
               method: 'post',
               //headers: {'Content-Type': 'application/json'},
               body: JSON.stringify(body)
             }

  return fetchAPI(url, init)
      .then(response => response.text());
}

export function updateReaderPropGeneric(readerId, cmd) {
  console.log("Update reader " + readerId + " " + cmd)
  let url = "http://" + readerId + ":18080/" + cmd + "?port=/dev/ttyPS0";
  let init = { method: 'get'}

  return fetchAPI(url, init)
      .then(response => response.text());
}
