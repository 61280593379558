import React from 'react'
import './proxy.css';

import ProxyHeader from './proxy_header';
import ProxyStatus from './proxy_status';
import ProxyControl from './proxy_control_main';
import ProxyControlGPIO from './proxy_control_gpios';
import ProxyControlRework from './proxy_control_rework';
import ProxyControlReworkDesc from './proxy_control_rework_desc';

import { fetchReaderJson } from './api';

const DEFAULT = {
   host: "", ip: "", proxyVer: "", sno: "", ver: "",
   rework: "", mcuVer: "", mcuCid: "", carrier: "", module: "",
   power: "", pressure: "", accel: "", accelDetails: "", temp: "", dac: "",
   rffeBoard: "", somOs: "", somImage: "", somMac: "", somZ: "", fpga: "",
   somDiskFree: "", network: "", mcu: "", som: "", lastUpdate: ""
}

/*
MCU: f9cd718 (staging)
Proxy: 2.2.0+0212903, where 0212903 is a patch or commit id
*/
const COMPATIABLE = {
  mcu: ['67f1cd0'], //, 'cf85976'
  proxy: ['2.2.0']
}

function cleanInput(text) {
  if (!text) { return ""; }
  if (text.includes("error")) { return ""; }
  if (text.toLowerCase().includes("no response")) { return ""; }
  if (text.includes("n/a")) { return ""; }
  return text.trim();
}

class Proxy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      reader: "", //user entry
      readerInfo: DEFAULT,
      loadingText: "",
      tab: "Main", //Main, GPIO J, Rework, ReworkDesc
    };

    this.readerRef = React.createRef();
    this.setReader = this.setReader.bind(this);
    this.fetchReaderInfo = this.fetchReaderInfo.bind(this);
    this.updateControlInfo = this.updateControlInfo.bind(this);

    /*
    window.onresize = () => {
     console.log("Window width: " + window.innerWidth);
     console.log("Window height: " + window.innerHeight);
   }
   */
  }

  componentDidMount() {
    console.log("component did mount");

    setInterval(
      ()=>this.updateTempPressure(),
      15000
    )
  }

  componentWillUnmount() {
  }

  setReader(e) {
    if (e.target.value.length >= 5) {
      this.setState({reader: e.target.value})
    }
    e.preventDefault();
  }

  fetchReaderInfo(e) {
    if (this.state.reader.length === 0) { return; }
    if (e.type === 'keypress' && e.code !== 'Enter') { return; }

    //this.readerRef.current.value = "";
    let newInfo = Object.assign({}, DEFAULT, {network: "on"})
    this.setState({readerInfo: newInfo, loadingText: "Loading Reader Info ..."}, ()=>this.queryReaderInfo(Object.keys(DEFAULT)));
  }

  updateTempPressure() {
    if (!this.state.reader) { return; }  //need a reader
    if (this.state.readerInfo['network']) { return; } //network need to be off

    console.log("Update reader json - " + new Date());
    let newInfo = Object.assign({}, this.state.readerInfo, { network: "on" })
    this.setState({readerInfo: newInfo}, ()=>this.queryReaderInfo(['proxyVer', 'mcuVer', 'dac', 'temp', 'pressure', 'accel', 'somZ']));
  }

  queryReaderInfo(keys) {
    console.log("Querying reader info - " + new Date())
    fetchReaderJson(this.state.reader, "info")
      .then(json => this.parseReaderQuery(json, keys))
      .catch(error => {
        console.log("Error - " + error);
        let newInfo = Object.assign({}, this.state.readerInfo, {'network': ""});
        this.setState({reader: "", readerInfo: newInfo, loadingText: "Load Reader Error " + error.message})
      })
  }

  getLastUpdate() {
    let now = new Date();
    let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
    let min = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
    let sec = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
    return (hour + ":" + min + ":" + sec)
  }

  /*
  input: Motion=0x3f0000, X= 0.00g, Y=0.00g, Z=0.98g
  'Flat up/down'
  Motion=0xc10202, X= 0.03g, Y= 0.03g, Z=-0.98g flat up
  Motion=0x3d01fe, X=-0.03g, Y= 0.02g, Z= 0.95g flat down

  'Portrait up/down'
  Motion=0x0342ff, X=-0.02g, Y= 1.03g, Z= 0.05g portrait up
  Motion=0x03bdff, X=-0.02g, Y=-1.05g, Z= 0.05g portrait down

  'Landscape up/down'
  Motion=0xff01c0, X=-1.00g, Y= 0.02g, Z=-0.02g landscape up
  Motion=0x02ff3e, X= 0.97g, Y=-0.02g, Z= 0.03g landscape down

  'Degrees from vertical'
  Motion=0x14003a, X= 0.91g, Y= 0.00g, Z= 0.31g 30deg from vertical
  Motion=0x240031, X= 0.77g, Y= 0.00g, Z= 0.56g 45deg from vertical
  Motion=0x34011f, X= 0.48g, Y= 0.02g, Z= 0.81g 60deg from vertical

  Question - is there 'Degrees from horizontal'?
  Motion=0x14003a, X= 0.00g, Y=0.91g, Z= 0.31g 30deg from horizontal
  Motion=0x240031, X= 0.00g Y=0.77g, Z= 0.56g 45deg from horizonal
  Motion=0x34011f, X=0.02g, Y=0.48g, Z= 0.81g 60deg from horizontal
*/

  interpretOrientation(str) {
    const isZero = (val) => (val > -.2 && val < .2 ? true : false);
    const isOne = (val) => (val > .8 && val < 1.2 ? true: false);
    const isNegOne = (val) => (val > - 1.2 && val < -.8 ? true: false);

    const regex = /motion|x|y|z|=|g|\s*/ig;
    let array = str.replaceAll(regex, "").split(",")
    let motion = array.shift();
    array = array.map(n => parseFloat(n));
    //console.log(array);

    let orientation = "";
    if (isZero(array[0]) && isZero(array[1])) {
      if (isNegOne(array[2])) { orientation = "Flat up"}
      if (isOne(array[2])) { orientation = "Flat down"}
    }

    if (isZero(array[0]) && isZero(array[2])) {
      if (isNegOne(array[1])) { orientation = "Portrait down"}
      if (isOne(array[1])) { orientation = "Portrait up"}
    }

    if (isZero(array[1]) && isZero(array[2])) {
      if (isNegOne(array[0])) { orientation = "Landscape up"}
      if (isOne(array[0])) { orientation = "Landscape down"}
    }

    if (!orientation) {
      if (isZero(array[1])) {
          if (array[2] > .6) {
              orientation = "60 deg from horizontal"
          } else if (array[2] > .45) {
            orientation = "45 deg from horizontal"
          } else if (array[2] > .30){
            orientation = "30 deg from horizontal"
          }
      }

      if (isZero(array[0])) {
        if (array[2] > .6) {
            orientation = "60 deg from vertical"
        } else if (array[2] > .45) {
          orientation = "45 deg from vertical"
        } else if (array[2] > .30){
          orientation = "30 deg from vertical"
        }
      }
    }

    return orientation;
  }

  parseReaderQuery(json, keys) {
    console.log("Parsing results - " + JSON.stringify(json));
    console.log("keys - " + JSON.stringify(keys))

    let rInfo = Object.assign({}, this.state.readerInfo);
    rInfo['lastUpdate'] = this.getLastUpdate();

    keys.forEach((key, i) => {
      //console.log(key);
      switch (key) {
        case 'host':
          rInfo['host'] = cleanInput(json['Hostname']);
          break;
        case 'ip':
          rInfo['ip'] = cleanInput(json['IP']);
          break;
        case 'proxyVer':
          //rInfo['proxyVer'] = cleanInput(json['Proxy ver']).split("+")[0];
          rInfo['proxyVer'] = cleanInput(json['Proxy ver']);
          break;
        case 'somOs':
          rInfo['somOs'] = cleanInput(json['SOM']['OS']);
          break;
        case 'somImage':
          rInfo['somImage'] = cleanInput(json['SOM']['Image Version']);
          break;
        case 'somMac':
          rInfo['somMac'] = cleanInput(json['Carrier']['MAC addr']);
          break;
        case 'somZ':
          let sz = cleanInput(json['SOM']['Zynq T']);
          rInfo['somZ'] = (Math.round(sz * 10) / 10).toFixed(1);
          break;
        case 'rffeBoard':
          rInfo['rffeBoard'] = cleanInput(json['RFFE']['Board ver']).replace("RFFE ID:", "").trim();
          break;
        case 'carrier':
          let carrierSt = cleanInput(json['Carrier']['Pwr Carrier']).replace("Carrier ID:", "").trim();
          //rInfo['carrier'] = carrierSt === "1" ? "Good" : "Bad (" + carrierSt + ")";
          rInfo['carrier'] = carrierSt === "1" ? "Good" : "No response";
          break;
        case 'rework':
          let temp = cleanInput(json['Carrier']['Board rework']).replace("Rework:", "").trim();
          if (!temp.startsWith("0x")) { temp = "0x" + temp }
          rInfo['rework'] = temp;
          break;
        case 'sno':
          let sno = cleanInput(json['Carrier']['MCU CID']);
          let hex_to_dec = parseInt(sno, 16);
          //rInfo['sno'] = hex_to_dec + " (" + sno + ")";
          rInfo['sno'] = hex_to_dec;
          break;
        case 'ver':
           //rInfo['ver'] = cleanInput(json['Carrier']['Board ver']).replace("0x", "").trim();
           rInfo['ver'] = "No response";
           break;
        case 'mcuVer':
          rInfo['mcuVer'] = cleanInput(json['Carrier']['MCU FW ver']);
          break;
        case 'power':
          //rInfo['power'] = cleanInput(json['Carrier']['Pwr 9.5']);
          rInfo['power'] = "No response";
          break;
        case 'module':
          let modSt = cleanInput(json['Carrier']['Pwr Module']).replace("Power Status:", "").trim();
          //rInfo['module'] = modSt === "1" ? "Good" : "Bad (" + modSt + ")";
          rInfo['module'] = modSt === "1" ? "Good" : "No response";
          break;
        case 'temp':
          let t = json['Carrier']['Temp'].split("=")[1].trim().replace("Deg C", "");
          rInfo['temp'] = (Math.round(t * 10) / 10).toFixed(1);
          break;
        case 'pressure':
          rInfo['pressure'] = json['Carrier']['Pressure'].split("=")[1].trim().replace("hPa", " hPa");
          break;
        case 'accel':
          //Motion=0x3f0000, X= 0.00g, Y=0.00g, Z=0.98g
          let motion = json['Carrier']['Accel'].split(",");
          rInfo['accelDetails'] = motion[1].trim() + ":" + motion[2].trim() + ":" + motion[3].trim();
          rInfo['accel'] = this.interpretOrientation(json['Carrier']['Accel'])
          break;
        case 'dac':
          //DAC=0xa680 Voltage=1.626V
          let arr = json['Carrier']['DAC word'].split(" ");
          rInfo['dac'] = arr[0].split("=")[1].trim();
          break;
        case 'fpga':
          rInfo['fpga'] = json['SOM']['FPGA'] ?
              Object.assign({},
                {versioncode: cleanInput(json['SOM']['FPGA']['versioncode'])},
                {timecode: cleanInput(json['SOM']['FPGA']['timecode'])},
                {datecode: cleanInput(json['SOM']['FPGA']['datecode'])},
                {subversioncode: cleanInput(json['SOM']['FPGA']['subversioncode'])},
              )
              :
              {versioncode: "", timecode: "", datecode: "", subversioncode: ""}
          break;
        case 'somDiskFree':
          rInfo['somDiskFree'] = cleanInput(json['SOM']['Disk Free']);
          break;
        case 'mcuCID':
          console.log("do nothing");
          break;
        default:
          console.log("Parse - Do Nothing")
      }
    });

    rInfo['network'] = "";

    //console.log(JSON.stringify(rInfo))
    if (this.state.reader) {
      if (this.state.loadingText.startsWith("Loading Reader Info")) {
        this.setState({loadingText: ""})
      }

      this.setState({readerInfo: rInfo}, ()=>this.checkCompatibility());
    };
  }

  checkCompatibility() {
    //disable for now
    /*if (!COMPATIABLE.mcu.includes(this.state.readerInfo['mcuVer'])) {
      this.setState({loadingText: "Error: Incompatible mcu version. Please update mcu firmware."})
    }*/

    /*if (!COMPATIABLE.proxy.includes(this.state.readerInfo['proxyVer'].split("+")[0])) {
      this.setState({loadingText: "Error: Incompatible proxy version. Please update proxy service."})
    }*/
  }

  updateControlInfo(commandHash) {
    console.log("update control info - " + JSON.stringify(commandHash))

    let newInfo = Object.assign({}, this.state.readerInfo, commandHash)
    this.setState({readerInfo: newInfo});
  }

  render() {
    return (
      <div className="Proxy">
        <div className="ReaderInput">
          <div className="ReaderIP">Reader</div>
          <input type="text"
              key="readerId"
              id="readerId"
              defaultValue="Name or IP"
              ref={this.readerRef}
              onChange={this.setReader}
              onClick={()=>{this.readerRef.current.value = ""; this.setState({reader: "", readerInfo: DEFAULT, loadingText: ""})}}
              onKeyPress={this.fetchReaderInfo}
              />
          <button onClick={this.fetchReaderInfo}>Connect</button>
        </div>
        <div className="Main">
          <div className="Loading" style={{color: this.state.loadingText.includes("Error") && "#E74C3C"}}>{this.state.loadingText}</div>
          <ProxyHeader
            key="proxyHeader"
            reader={this.state.reader}
            info={this.state.readerInfo}
            compatible={COMPATIABLE.proxy.includes(this.state.readerInfo['proxyVer'].split("+")[0])}
          />
          <div className="StatusText">Status</div>
          <ProxyStatus
            key="proxyStatus"
            info={this.state.readerInfo}
            compatible={COMPATIABLE.mcu.includes(this.state.readerInfo['mcuVer'])}
          />
          <div className="ControlTabs">
            {
              this.state.tab === 'Main' ?
              <div className="Tab SelectedTab" data-tab="Main" disabled>MAIN</div>
              :
              <div className="Tab" data-tab="Main" onClick={()=>{this.setState({tab: "Main"})}}>MAIN</div>
            }
            {
              this.state.tab === 'GPIO J' ?
              <div className="Tab SelectedTab" data-tab="GPIO J" disabled>GPIOs J</div>
              :
              <div className="Tab" data-tab="GPIO J" onClick={()=>this.setState({tab: "GPIO J"})}>GPIOs J</div>
            }
            {
              this.state.tab === 'Rework' ?
              <div className="Tab SelectedTab" data-tab="Rework" disabled>Rework</div>
              :
              <div className="Tab" data-tab="Rework" onClick={()=>this.setState({tab: "Rework"})}>Rework</div>
            }
            {
              this.state.tab === 'ReworkDesc' ?
              <div className="Tab SelectedTab" data-tab="ReworkDesc" disabled>Rework Desc</div>
              :
              <div className="Tab" data-tab="ReworkDesc" onClick={()=>this.setState({tab: "ReworkDesc"})}>Rework Desc</div>
            }
          </div>
          <div className="ControlText">Control</div>
          {
            this.state.tab === 'Main' &&
              <ProxyControl
                key="proxyControl"
                reader={this.state.reader}
                info={this.state.readerInfo}
                compatible={this.state.reader && COMPATIABLE}
                updateControlInfo={this.updateControlInfo}
                updateStatus={(text)=>{this.setState({loadingText: text})}}
              />
            }
            {
            this.state.tab === 'GPIO J' &&
              <ProxyControlGPIO
                key="proxyControlGpioJ"
                reader={this.state.reader}
                updateControlInfo={this.updateControlInfo}
                updateStatus={(text)=>{this.setState({loadingText: text})}}
              />
            }
            {
            this.state.tab === 'Rework' &&
              <ProxyControlRework
                key="proxyControlRework"
                reader={this.state.reader}
                info={this.state.readerInfo}
                updateControlInfo={this.updateControlInfo}
                updateStatus={(text)=>{this.setState({loadingText: text})}}
              />
            }
            {
            this.state.tab === 'ReworkDesc' &&
              <ProxyControlReworkDesc
                key="proxyControlReworkDesc"
              />
            }
        </div>
      </div>
    );
  }
}

export default Proxy;

/*
const mock =
  {
    "SOM":{
    "Zynq T":"31.4984",
    "OS":"Ubuntu 18.04.5 LTS\n"
    },
    "RFFE":{
      "Board ver":"n/a"
    },
    "Carrier":{
      "MAC addr":"00:04:4b:00:68:94",
      "Pwr Carrier":"n/a",
      "Board rework":"n/a",
      "Board ver":"n/a",
      "MCU CID":" 0x77\n\n",
      "MCU FW ver":"42af6b5\n\n",
      "Pwr 9.5":"n/a",
      "Pwr Module":"n/a",
      "Temp":"Temperature=29.50Deg C\n\n",
      "Pressure":"Pressure=1003hPa\n\n",
      "Accel":"Motion=0x4200ff, X=-0.02g, Y= 0.00g, Z= 1.03g\n\n",
      "DAC word":"DAC=0xa680 Voltage=1.626V\n\n"
    },
    "Proxy ver":"4c2e152",
    "IP":"172.16.2.217",
    "Hostname":"sdrj5"
  }

  MCU: f9cd718 (staging)
  Proxy: 2.2.0+0212903, where 0212903 is a patch or commit id
*/
