import React from 'react'

function systemImg(readerCode) {
  switch (readerCode) {
    case 'pnms':
      return (<img className="Power" src={require('./img/System Status 0 New.png')} alt="Reader Status" />);
    case 'Pnms':
      return (<img className="Power" src={require('./img/System Status 1 New.png')} alt="Reader Status" />);
    case 'PNms':
      return (<img className="Power" src={require('./img/System Status 2 New.png')} alt="Reader Status" />);
    case 'PNMs':
      return (<img className="Power" src={require('./img/System Status 3 New.png')} alt="Reader Status" />);
    case 'PNmS':
      return (<img className="Power" src={require('./img/System Status 5 New.png')} alt="Reader Status" />);
    case 'PNMS':
      return (<img className="Power" src={require('./img/System Status 4 New.png')} alt="Reader Status" />);
    default:
      return (<img className="Power" src={require('./img/System Status 0 New.png')} alt="Reader Status" />);
  }
}


class ProxyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      systemCode: "pnms",
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.info) !== JSON.stringify(this.props.info)) {
      let readerStatus = ['p', 'n', 'm', 's']; //power, network, mcu, som

      if (this.props.reader) { readerStatus[0] = "P" }
      if (this.props.info.network) { readerStatus[1] = "N" }
      if (this.props.info.mcu) { readerStatus[2] = 'M' }
      if (this.props.info.som) { readerStatus[3] = 'S' }
      this.setState({systemCode: readerStatus.join('')})
    }
  }

  componentWillUnmount() {
  }

  //div>Proxy Ver: <span style={{color: !this.props.compatible && "#E74C3C"}}>{this.props.info.proxyVer}</span></div>
  render() {
    return (
      <div>
        <h1>Reader Proxy Service</h1>
        <div className="Host">
          <div>Host: {this.props.info.host}</div>
          <div>IP: {this.props.info.ip}</div>
          <div>Proxy Ver: {this.props.info.proxyVer}</div>
          <div>Last Update: {this.props.info.lastUpdate}</div>
        </div>
        <div className="Images">
          {systemImg(this.state.systemCode)}
          <img className="Logo" src={require('./img/Zainar.png')} alt="Zainar" />
        </div>
      </div>
    );
  }
}

export default ProxyHeader;

/*
const INFO = {
  host: "srdj22", ip: "192.168.2.124", proxyVer: "1.0.0", sno: "022", ver: "J01",
  rework: "0x0002", mcuVer: "1.0.0", mcuCid: "0xBF0A", carrier: "Good", module: "Good",
  power: "Good", pressure: "1008 kPa", accel: "Vertical", temp: "25.5 deg C", dac: "0x23ac",
  rffeBoard: "01", somOs: "Ubuntu 18.4", somMac: "12.22.bc.a2", somZ: "54.5 deg C"
}
*/
