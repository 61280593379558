import React from 'react'

import { postReaderJson } from './api';

const DEFAULT = [0,0,0,0,0,0,0,0] // GPIOs 1-8
const CONFIGS = {
  'Calibration 1' : [1,0,null,null,null,null,0,0],
  'Calibration 2' : [0,0,1,0,1,0,1,1],
  'Calibration 3 (Ant 1)' : [0,0,1,0,0,0,1,1],
  'Calibration 3 (Ant 2)' : [0,0,0,0,0,0,1,1],
  'Tx --> Ant 1' : [null,0,1,0,null,null,0,1],
  'Tx --> Ant 2' : [1,0,null,null,null,null,0,0],
  'Ant 1 --> Rx 1' : [0,1,1,1,1,1,1,0],
  'Ant 2 --> Rx 1' : [0,1,0,1,null,1,1,0],
  'Rx2 path' : [null,0,null, null, null, null,0,0],
  'No GPIO' : [0,1,1,1,0,1,0,0]
}

const MAP = [74, 75, 80, 79, 82, 83, 86, 87, 76, 77]; //GPIO->Offset MAP

function format(value) {
  let val = "X";
  if (value === 0) { val = "L" }
  if (value === 1) { val = "H" }

  return val;
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

class ProxyControlGPIO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gpio: DEFAULT, //checkboxes
      config: "", //dropdown
      configOptions: CONFIGS,
      newConfigName: "",
    };

    this.setRework = this.setRework.bind(this);
    this.addConfig = this.addConfig.bind(this);
    this.removeConfig = this.removeConfig.bind(this);
    this.updateReader = this.updateReader.bind(this);
  }

  componentDidMount() {
    //Get cookies and add to configOptions
    let uniq = {};
    getCookie("ZainarProxyGPIOs").split("|").forEach((n, index) => {
      if (!n) { return; }
      uniq[n.split(":")[0]] = n.split(":")[1].split(",").map(n=> parseInt(n));
    })

    this.setState({configOptions: Object.assign({}, CONFIGS, uniq)});
  }

  componentWillUnmount() {
  }

  setRework(e) { //gpio
    let index = e.target.value.split("_")[0];
    let val = e.target.value.split("_")[1];
    let newGpio = Object.assign([], this.state.gpio)
    newGpio[index] = parseInt(val);
    this.setState({config: "", gpio: newGpio});
  }

  addConfig() {
    if (!this.state.newConfigName) { return; }

    console.log("Add config to cookie")
    let uniq = {};
    getCookie("ZainarProxyGPIOs").split("|").forEach((n, index) => {
      if (!n) { return; }
      uniq[n.split(":")[0]] = n.split(":")[1];
    })
    uniq[this.state.newConfigName] = this.state.gpio;

    let cookieVal = "";
    for(const key in uniq) {
      cookieVal = cookieVal + "|" + key + ":" + uniq[key];
    }

    setCookie("ZainarProxyGPIOs", cookieVal, 90);
    console.log(getCookie("ZainarProxyGPIOs"));

    //Add to config configOptions
    let options = Object.assign({}, this.state.configOptions, {[this.state.newConfigName]: this.state.gpio});
    this.setState({configOptions: options, newConfigName: ""});
  }

  removeConfig() {
    if (!this.state.config) { return }

    console.log("remove config");
    let uniq = {};

    //remove from cookie
    getCookie("ZainarProxyGPIOs").split("|").forEach((n, index) => {
      if (!n) { return; }
      uniq[n.split(":")[0]] = n.split(":")[1];
    })
    delete uniq[this.state.config];

    let cookieVal = "";
    for(const key in uniq) {
      cookieVal = cookieVal + "|" + key + ":" + uniq[key];
    }
    setCookie("ZainarProxyGPIOs", cookieVal, 90);
    //console.log(getCookie("ZainarProxyGPIOs"));

    uniq = Object.assign({}, this.state.configOptions);
    delete uniq[this.state.config];
    this.setState({config: "", configOptions: uniq, gpio: DEFAULT})
  }

  //gpio74”: 0, “gpio75”: 0, “gpio80”: 1, “gpio79”: 1, “gpio82”: 0, “gpio83”: 1, “gpio86”: 1, “gpio87”: 0, “gpio76”: 1, “gpio77”: 1
  //POST http://localhost:18080/gpios -H 'Content-Type: application/json' -d '{"gpios": [{"offset": 74,"value": 1},{"offset": 77,"value": 0} .... {"offset": 81,"value": 1}]}'
  updateReader() {
    console.log("update reader - gpios")
    let body = {'gpios': this.state.gpio.map((gpio,  index) => ({"offset": MAP[index], "value": gpio})).filter(n => n["value"] !== null)}
    console.log(JSON.stringify(body));

    this.props.updateControlInfo({"network": "on"});
    this.props.updateStatus("Updating GPIOs ...")
    postReaderJson(this.props.reader, "gpios", body)
    .then(text => this.props.updateStatus(text))
    .then(()=> this.props.updateControlInfo({"network": ""}))
    .catch(error=> console.log(error) )
  }

  render() {
    return (
      <div className="Control">
        <div className="Panel">
          <div className="FieldsGpio">
            <div className="FieldGpio">GPIO#0 SW0:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="0_0" checked={this.state.gpio[0] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="0_1" checked={this.state.gpio[0] === 1 && true} onChange={this.setRework}/>H
            </div>
            <div className="FieldGpio">GPIO#4 SW4:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="4_0" checked={this.state.gpio[4] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="4_1" checked={this.state.gpio[4] === 1 && true} onChange={this.setRework}/>H
            </div>
          </div>
          <div className="FieldsGpio">
            <div className="FieldGpio">GPIO#1 SW1:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="1_0" checked={this.state.gpio[1] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="1_1" checked={this.state.gpio[1] === 1 && true} onChange={this.setRework}/>H
            </div>
            <div className="FieldGpio">GPIO#5 SW5:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="5_0" checked={this.state.gpio[5] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="5_1" checked={this.state.gpio[5]=== 1 && true} onChange={this.setRework}/>H
            </div>
          </div>
          <div className="FieldsGpio">
            <div className="FieldGpio">GPIO#2 SW2:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="2_0" checked={this.state.gpio[2] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="2_1" checked={this.state.gpio[2] === 1 && true} onChange={this.setRework}/>H
            </div>
            <div className="FieldGpio">GPIO#6 LNA:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="6_0" checked={this.state.gpio[6] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="6_1" checked={this.state.gpio[6] === 1 && true} onChange={this.setRework}/>H
            </div>
          </div>
          <div className="FieldsGpio">
            <div className="FieldGpio">GPIO#3 SW3:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="3_0" checked={this.state.gpio[3] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="3_1" checked={this.state.gpio[3] === 1 && true} onChange={this.setRework}/>H
            </div>
            <div className="FieldGpio">GPIO#7 PA:</div>
            <div className="ValueGpio">
              <input className="Checkbox" type="checkbox" value="7_0" checked={this.state.gpio[7] === 0 && true} onChange={this.setRework}/>L
              <input className="Checkbox" type="checkbox" value="7_1" checked={this.state.gpio[7] === 1 && true} onChange={this.setRework}/>H
            </div>
          </div>
          <div className="FieldsGpio2">
            <div className="FieldGpio">New Config:</div>
            <div className="ValueGpio ValueGpio2">
              <input type="text" id="config_name" value={this.state.newConfigName} onChange={(e)=>this.setState({newConfigName: e.target.value})}/>
              <button id='add_config' value=""  onClick={this.addConfig}>Add</button>
            </div>
          </div>
        </div>
        <div className="Panel">
        <div className="FieldsGpio">
          <div className="FieldGpio">Config Name:</div>
          <div className="ValueGpio ValueGpio2">
            <input
              type="text"
              id="program_mac_addr"
              list="cars"
              value={this.state.config}
              onClick={()=>this.setState({config: ""})}
              onChange={(e)=>this.setState({config: e.target.value, gpio: this.state.configOptions[e.target.value]})}
            />
            <datalist id="cars">
              {Object.keys(this.state.configOptions).map(k => <option key={k}>{k}</option>)}
            </datalist>
            {
              Object.keys(CONFIGS).includes(this.state.config) ?
              <button id='removeInactive' value="" className="disabled" disabled>Remove</button>
              :
              <button id='removeActive' value="" onClick={this.removeConfig}>Remove</button>
            }
          </div>
        </div>
        <div className="DetailsGpio">
          { this.state.config &&
            <div>
            SW0: {format(this.state.configOptions[this.state.config][0])},
            SW1: {format(this.state.configOptions[this.state.config][1])},
            SW2: {format(this.state.configOptions[this.state.config][2])},
            SW3: {format(this.state.configOptions[this.state.config][3])},
            SW4: {format(this.state.configOptions[this.state.config][4])},
            SW5: {format(this.state.configOptions[this.state.config][5])},
            LNA: {format(this.state.configOptions[this.state.config][6])},
            PA: {format(this.state.configOptions[this.state.config][7])}
            </div>
          }
        </div>
          <div className="ValueGpio3">
            { this.props.reader === "" ?
              <button id='updateReader1' value="" className="disabled" disabled>Update Reader</button>
              :
              <button id='updateReader2' value="" onClick={this.updateReader}>Update Reader</button>
            }
            <button id='reset' value="" onClick={()=>{this.setState({config: "", gpio: DEFAULT, newConfigName: ""}) /*;setCookie("ZainarProxyGPIOs", "", 90)*/}}>Reset</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ProxyControlGPIO;
