export const REWORK = [
  "U38 regulator enable threshold to 10.65V.  Replace R398 to 162K, R399 to 20K.",
  "Remove U21 feed-forward caps 5.0V C241, 3.9V C242",
  "Move R415 ground connection to the adjacent R358 net VPORTN by unsoldering and rotating R415 45 degrees.",
  "temporarily lift U44 p. 18 to remove leak path between VPORTN and GND_48V and allow detection of PoE.  Does not affect 12V ext power.",
  "Set isolated feedback DC reference. Wire Q64 base to VREF U24 p. 14 and R360.",
  "Remove 5.0V and 3.9V U21 feed forward caps to prevent oscillation. Remove C241, C242.",
  "5.0V instability fix: Replace R107 to 4.02K, C105 to 150nF,  C310 to 12pF to accomplish 10KHz BW reduced from original 20 KHz. ",
  "Intermittent 9.5V ext startup oscillation: Cut trace betweeen U38 p. 21 and R335, changing PGOOD_9V5_EXT to a 3.9V output tracking VDD_9V5_EXT because the open-drain PGOOD output from the switcher would introduce a power-up oscillation in some circumstances.",
  "TX and RX to Zynq swapped (perspective ambiguity following ADI carrier schematic).Fix UART coms to Zynq:Cut trace between J4 p. 98 and via.Cut trace between J4 p. 99 and via.Wire net USB_UART_RXD_ZYNQ_1V8 from via to pin 98.Wire net USB_UART_TXD_ZYNQ_1V8 from via to pin 99.",
  "Intermittent PoE startup oscillation:Replace R210 to 10.7KReplace R211 to 1.62K",
  "R255 0 Ohm jumper to MCU_NRST re-installed to compatible with revised code supporting this feature",
  "Load MCU version 204592b fixing SoM power control.Current behavior is to power on the SoM on MCUboot following the hardware time constant of about100ms, but this can be configured.Note:  MCU code must be loaded first, then:Connect MCU PB4 (p. 27) pad on PCB undoingworkaround in column N",
  "Increase PoE duty cycle clamp:Replace R364 to 249K, R390 to 32.4K.Charge VCC fast enough for PoE timeout:Replace D49, D50 to 0 OhmsAdjust Vout:Replace R373 to 133K, R372 to 47.5K, C270 to 100pSet Soft Start Max Duty Cycle:Replace R360 to 35.7K, R361 to 102KReduce Sout clamp to Out delay:Replace R357 to 41.2KFix transformer buzzing (?), return path for leakage:Add 3.3nF 812YA250332KJTSYX high-voltage safety capacitorbetween GND_48V and GND (below)",
  "adjust filter damping to accomodate additional regulator capacitance:Replace R341 to 150mOhms.  Replace C253 to 2 each 100uF TAIYO YUDEN LMK325AC6107MM-PAdd regulator PCB:Solder ADM7154ACPZ-3.3-R7 regulator PCB to ground plane using solder paste and hot air.Cut trace at FB1 from 2 vias connecting to VDD_3V3.Wire FB1 to ADM7154 output p. 7,8/C4.  Wire ADM7154 input p.2/C3 to VDD_3V9 at C252,securing wire with glue along the route, avoiding regulator inductor magnetic fields.   ",
  "Implement PoE shutdown by external 12V power:Cut trace between u38 p.21 and R335.  Replace R335 to 6.19K.  Replace R413 to 100K.Wire net PGOOD_9V5_EXT at either side of R394 jumper to optoisolator LTV-352T p. 1(LED anode, positive side). Connect a 2.21K resistor from LTV-352T p. 2 (cathode,LED negative) to GND. Wire p. 4 to VDD_48V_D at C292. Wire p. 3 to net SHDN at U44 p.18(note U44 p.18 is lifted from the PCB to disconnect it from this circuit).On hold, will disable 12V ext instead. See drawing below.",
  "Fix PoE failures, LTC4269-2 hot swap MOSFETbody diode failure:Solder diode RB021VAM90TR betweenU24 p. 5, 6 net VPORTN to GND_48Vas close as possible to U24, cathode facingGND_48V, as shown below.",
  "Fix PoE poor response to load transients:Replace R373 to 13.3K, R372 to 4.75K, remove C270",
  "Fix too-high slope compensation amplitude, causing inconsistent PoE response across different PCBs.  For those PCBs with C270 installed in attempt to make them work, C270 should be removed with this fix: Replace R356 to 1K.",
  "Flash TX LED faster: Replace R285 to 499K. Not a high priority, as readers are available.",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty",
  "empty"
]
