import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
<React.StrictMode>
  <App />
</React.StrictMode>

https://www.reddit.com/r/reactjs/comments/tx8zwt/why_does_componentdidmount_lifecycle_method_run/
https://stackoverflow.com/questions/71755119/reactjs-componentdidmount-executes-twice
*/
