import React from 'react'
import { unlockReader, updateReaderProp, burnReaderProp, getBurnReaderPropUpdate, updateReaderPropGeneric } from './api';

function hexToBinary(n) {
  return (parseInt(n, 16).toString(2)).padStart(16, '0');
}

function binaryToHex(n) {
  return(parseInt(n, 2).toString(16)).padStart(8, '0')
}

class ProxyControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serialNo: "",
      mac: "",
      reworkArray: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], //last 16 bits of 32bit, 8 digit hex
      dac: "",
      mcuFw: "",
    };

    this.setRework = this.setRework.bind(this);
    this.updateReader = this.updateReader.bind(this);
    this.updateReaderGeneric = this.updateReaderGeneric.bind(this);
    this.burnReader = this.burnReader.bind(this);
    this.sNoRef = React.createRef();
    this.macRef = React.createRef();
    this.dacRef = React.createRef();
    this.mcuFwRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.info.rework) {
      let binary = hexToBinary(this.props.info.rework).split("").slice(-16).map(n => parseInt(n));
      this.setState({reworkArray: binary});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.info.rework !== prevProps.info.rework) {
      //console.log(this.props.info.rework);
      //console.log(hexToBinary(this.props.info.rework));
      let binary = hexToBinary(this.props.info.rework).split("").slice(-16).map(n => parseInt(n));
      this.setState({reworkArray: binary});
    }
  }
  componentWillUnmount() {
  }

  setRework(e) {
    //console.log(e.target.value + " - " + e.target.checked)
    let rework = [...this.state.reworkArray];

    if (e.target.checked) {
      rework[e.target.value] = 1;
    } else {
      rework[e.target.value] = 0;
    }
    this.setState({reworkArray: rework});
    //console.log(binaryToHex(rework.join("")))
    //e.preventDefault();
  }

  burnReader(e) {
    if (e.type === 'keypress' && e.code !== 'Enter') { return; }

    if (!this.props.reader) {
      console.error("No reader");
      this.props.updateStatus("Error: No reader");
      return;
    }

    if (this.props.info.network) {
      console.error("Another process");
      this.props.updateStatus("Error: Another process is running ... Please try again when network status is grey ...");
      /*setTimeout(() => {
        this.props.updateStatus("")
      }, 5000);*/
      return;
    }

    this.props.updateStatus("");

    if (!this.state.mcuFw || this.state.mcuFw.length !== 7) {
      console.error("Invalid MCU FW value");
      this.props.updateStatus("Error: Invalid MCU FW value");
      return;
    }

    this.props.updateControlInfo({"network": "on"});
    this.props.updateStatus("Updating MCU FW ... Process takes ~ 60 seconds ...")

    burnReaderProp(this.props.reader, this.state.mcuFw)
      .then(text => console.log(text))
      .then(()=> {this.queryBurnStatus(1, "")})
      .catch(error => console.log(error))
  }

  queryBurnStatus(index, update_status) {
    console.log("update_status " + update_status);
    if (update_status === "UPDATE_COMPLETE" || index === 6) {
      this.props.updateControlInfo({"network": "", "mcuVer": ""});
      this.setState({mcuFw: ""});
      this.mcuFwRef.current.value = "";
      setTimeout(()=>{
        this.props.updateStatus("Status is " + update_status + ". MCU FW Ver will update soon ...");
      }, 10000);
      setTimeout(()=>this.props.updateStatus(""), 15000);
      return;
    }

    setTimeout(()=> {
        getBurnReaderPropUpdate(this.props.reader)
          .then(text => {
            let seconds = 60 - index * 10;
            this.props.updateStatus("Updating MCU FW ...  " + seconds + " seconds left ... ");
            this.queryBurnStatus(index + 1, text)
          })
          .catch(err => console.log(err))
      }, 10000);


    /*()=> {
      let seconds = [1, 5, 10, 15,  20, 25, 30, 35, 40, 45, 50, 55, 60];
      seconds.forEach((item, i) => {
        setTimeout(()=> {
            this.props.updateStatus("Updating MCU FW ...  " + (60 - item) + " seconds left ...");
          }, item * 1000);
      });

      setTimeout(()=> {
        getBurnReaderPropUpdate(this.props.reader)
          .then(text => console.log(text))
          .catch(err => console.log(err))

        this.props.updateStatus("");
        this.setState({mcuFw: ""});
        this.mcuFwRef.current.value = "";
        this.props.updateControlInfo({"network": ""});
      }, 65000)
    })
    */
  }

  /*burnReader(e) {
    if (e.type === 'keypress' && e.code !== 'Enter') { return; }

    if (!this.props.reader) {
      console.error("No reader");
      this.props.updateStatus("Error: No reader");
      return;
    }

    this.props.updateStatus("");

    if (!this.state.mcuFw || this.state.mcuFw.length !== 7) {
      console.error("Invalid MCU FW value");
      this.props.updateStatus("Error: Invalid MCU FW value");
      return;
    }

    this.props.updateControlInfo({"network": "on"});
    this.props.updateStatus("Updating MCU FW ... Process takes 60 seconds ...")

    burnReaderProp(this.props.reader, this.state.mcuFw)
      .then(text => console.log(text))
      .then(()=> {
        let seconds = [1, 5, 10, 15,  20, 25, 30, 35, 40, 45, 50, 55, 60];
        seconds.forEach((item, i) => {
          setTimeout(()=> {
              this.props.updateStatus("Updating MCU FW ...  " + (60 - item) + " seconds left ...");
            }, item * 1000);
        });

        setTimeout(()=> {
          getBurnReaderPropUpdate(this.props.reader)
            .then(text => console.log(text))
            .catch(err => console.log(err))

          this.props.updateStatus("");
          this.setState({mcuFw: ""});
          this.mcuFwRef.current.value = "";
          this.props.updateControlInfo({"network": ""});
        }, 65000)
      })
      .catch(error => console.log(error))
  }*/

  updateReader(e) {
    if (e.type === 'keypress' && e.code !== 'Enter') { return; }

    if (!this.props.reader) {
      console.error("No reader");
      this.props.updateStatus("Error: No reader");
      return;
    }

    this.props.updateStatus("");

    let command = "";
    if (e.type === 'keypress' && e.code === 'Enter') {
      command = e.target.dataset.value;
    } else { //button click
      command = e.target.value;
    }

    console.log(command)
    let value = "";
    switch (command) {
      case 'Dev_Id':
        if (!this.state.serialNo) {
          console.error("No serial no value");
          this.props.updateStatus("Error: No serial no value");
          return;
        }
        value = this.state.serialNo;
        this.props.updateStatus("Update Reader Serial No ...")
        break;
      case 'dac':
        console.log("dac")
        if (!this.state.dac) {
          console.error("No dac value");
          this.props.updateStatus("Error: No dac value");
          return;
        }
        value = this.state.dac;
        this.props.updateStatus("Update Reader DAC ...");
        break;
      case 'rework':
        console.log("rework");
        value = binaryToHex(this.state.reworkArray.join(""));
        this.props.updateStatus("Update Reader Rework ...");
        break;
      default:
        //do nothing
    }

    this.props.updateControlInfo({"network": "on"});
    unlockReader(this.props.reader, command)
    .then(text => console.log(text))
    .then(() => {
      updateReaderProp(this.props.reader, command, value)
        .then(text => console.log(text))
        .then(()=> {
          this.props.updateStatus("");
          this.resetRefs(command, value);
        })
        .catch(error => console.log(error))
    })
    .catch(error => console.log(error))
  }


  //for sys update
  updateReaderGeneric(e) {
    if (e.type === 'keypress' && e.code !== 'Enter') { return; }

    if (!this.props.reader) {
      console.error("No reader");
      this.props.updateStatus("Error: No reader");
      return;
    }

    this.props.updateStatus("");
    let command = "";
    if (e.type === 'keypress' && e.code === 'Enter') {
      command = e.target.dataset.value;
    } else { //button click
      command = e.target.value;
    }

    console.log(command)
    switch (command) {
      case 'sys_update':
        this.props.updateStatus("Update Reader Proxy Service ...")
        break;
      default:
        //do nothing
    }

    this.props.updateControlInfo({"network": "on"});
    updateReaderPropGeneric(this.props.reader, command)
      .then(text => console.log(text))
      .then(()=> {
        this.props.updateStatus("");
        this.resetRefs(command, "");
      })
      .catch(error => console.log(error))
  }

  resetRefs(command, value) {
    if (command === "Dev_Id") {
      this.sNoRef.current.value = "";
      this.setState({serialNo: ""})
      this.props.updateControlInfo({"sno": value, "network": ""})
    }

    if (command === 'dac') {
      this.dacRef.current.value = "";
      this.setState({dac: ""});
      if (!value.startsWith("0x")) { value = "0x" + value }
      this.props.updateControlInfo({"dac": value, "network": ""})
    }

    if (command === 'rework') {
      this.props.updateControlInfo({"rework": "0x" + parseInt(value, 16).toString(16), "network": ""})
    }

    if (command === 'sys_update') {
      this.props.updateControlInfo({"network": ""})
      console.log("sys_update done");
    }
  }

  render() {
    return (
      <div className="Control">
        <div className="Fields">
          <div className="Field">
            Program Serial No:
          </div>
          <div className="Value">
            <input type="text"
              id="program_serial_no"
              defaultValue="Serial No (3 digit decimal)"
              data-value="Dev_Id"
              onChange={(e)=>this.setState({serialNo: e.target.value})}
              ref={this.sNoRef}
              onClick={()=>this.sNoRef.current.value = ""}
              onKeyPress={this.updateReader}
            />
            <button id='serialNo' value='Dev_Id' onClick={this.updateReader}>Set SNo</button>
          </div>
          <div className="Field">
            Program MAC Address:
          </div>
          <div className="Value">
            <input type="text" id="program_mac_addr" defaultValue="last 4 digits in hex" ref={this.macRef} onClick={()=>this.macRef.current.value = ""}/>
            <button id='mac' value='mac' onClick={this.updateReader} className="disabled" disabled>Set MAC</button>
          </div>
        </div>
        <div className="Fields">
          <div className="Field">
            Set DAC Value (OCXO):
          </div>
          <div className="Value">
            <input
              type="text"
              id="set_dac_value"
              defaultValue="4 digit hexadecimal"
              data-value="dac"
              ref={this.dacRef}
              onClick={()=>this.dacRef.current.value = ""}
              onChange={(e)=>this.setState({dac: e.target.value})}
              onKeyPress={this.updateReader}
              />
            <button id='dac' value='dac' onClick={this.updateReader}>Set DAC</button>
          </div>
          <div className="Field2">Program MCU FW:
            <div className="Subnote">Current FW Ver: {this.props.info.mcuVer}</div>
            <div className="Subnote">Compatible FW Ver: {this.props.compatible.mcu && this.props.compatible.mcu.join(",")}</div>
          </div>
          <div className="Value">
            <input
              type="text"
              list="cars"
              id="set_mcufw_value"
              data-value="mcuFw"
              defaultValue="FW version (commit_id)"
              ref={this.mcuFwRef}
              onClick={()=>this.mcuFwRef.current.value = ""}
              onChange={(e)=>this.setState({mcuFw: e.target.value})}
              onKeyPress={this.burnReader}
            />
            <datalist id="cars">
              <option>STAGING</option>
              <option>67f1cd0</option>
              <option>f9cd718</option>
              <option>42af6b5</option>
              <option>21befa5</option>
            </datalist>
            <button id='mcuFw' value='mcuFw' onClick={this.burnReader}>Burn</button>
          </div>
        </div>
        <div className="Fields">
          <div className="Field"/>
          <div className="Value3">
            <button className="ResetMCU" id="proxy" value="sys_update" onClick={this.updateReaderGeneric}>Update Proxy</button>
            <button className="ResetMCU disabled" disabled>Reset MCU</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ProxyControl;

/*
<input
  type="text"
  id="set_mcufw_value"
  data-value="mcuFw"
  defaultValue="42af6b5 or 21befa5"
  ref={this.mcuFwRef}
  onClick={()=>this.mcuFwRef.current.value = ""}
  onChange={(e)=>this.setState({mcuFw: e.target.value})}
  onKeyPress={this.burnReader}
/>
<button className="disabled" disabled>Browse</button>

Rework
/mcu?port=/dev/ttyPS0&cmd=rework 0xFFFFFFFF
/mcu?port=/dev/ttyPS0&cmd=rework <new_value> -- in a hex
Read:
/mcu?port=/dev/ttyPS0&cmd=rework

You can use sdrj6 for testing (edited)

Proxy Ver -
1. display the entire proxy service version

2. Lock the current dashboard to

MCU: f9cd718 (staging)
Proxy: 2.2.0+0212903, where 0212903 is a patch or commit id

3. Will code a mechanism to store a list of compatible mcu and proxy version in the dashboard

4. If reader MCU is not compatible. Will display msg to update mcu firmware.

5. if proxy service not compatible, will display msg to update proxy service on reader.

GET /sys_update
*/
