import React from 'react'
import { unlockReader, updateReaderProp } from './api';
import { REWORK } from './rework_desc';

function hexToBinary(n) {
  //return (parseInt(n, 16).toString(2).padStart(32, '0'));
  return (parseInt((n.replace("0x", "")), 16).toString(2).padStart(32, '0'));
}

function binaryToHex(n) {
  let temp = parseInt(n, 2).toString(16).padStart(8, '0');
  return temp;
}

class ProxyControlRework extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reworkArray: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], //32bit, 16 digit hex
    };

    this.setRework = this.setRework.bind(this);
    this.updateReader = this.updateReader.bind(this);
  }

  componentDidMount() {
    if (this.props.info.rework) {
      let binary = hexToBinary(this.props.info.rework).split("").map(n => parseInt(n));
      this.setState({reworkArray: binary});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.info.rework !== prevProps.info.rework) {
      console.log(this.props.info.rework);
      console.log(hexToBinary(this.props.info.rework));
      let binary = hexToBinary(this.props.info.rework).split("").map(n => parseInt(n));
      this.setState({reworkArray: binary});
    }
  }

  componentWillUnmount() {
  }

  setRework(e) {
    //console.log(e.target.value + " - " + e.target.checked)
    let rework = [...this.state.reworkArray];

    if (e.target.checked) {
      rework[e.target.value] = 1;
    } else {
      rework[e.target.value] = 0;
    }
    this.setState({reworkArray: rework});
    console.log(binaryToHex(rework.join("")))
    //e.preventDefault();
  }

  updateReader(e) {
    if (e.type === 'keypress' && e.code !== 'Enter') { return; }

    if (!this.props.reader) {
      console.error("No reader");
      this.props.updateStatus("Error: No reader");
      return;
    }

    this.props.updateStatus("");

    let command = "";
    if (e.type === 'keypress' && e.code === 'Enter') {
      command = e.target.dataset.value;
    } else { //button click
      command = e.target.value;
    }

    console.log(command)
    let value = "";
    switch (command) {
      case 'rework':
        console.log("rework");
        value = binaryToHex(this.state.reworkArray.join(""));
        this.props.updateStatus("Update Reader Rework ...");
        break;
      default:
        //do nothing
    }

    this.props.updateControlInfo({"network": "on"});
    unlockReader(this.props.reader, command)
    .then(text => console.log(text))
    .then(() => {
      updateReaderProp(this.props.reader, command, value)
        .then(text => console.log(text))
        .then(()=> {
          this.props.updateStatus("");
          this.resetRefs(command, value);
        })
        .catch(error => console.log(error))
    })
    .catch(error => console.log(error))
  }

  resetRefs(command, value) {
    if (command === 'rework') {
      this.props.updateControlInfo({"rework": "0x" + parseInt(value, 16).toString(16), "network": ""})
    }
  }

  render() {
    return (
      <div className="Control">
        <div className="Fields">
          <div className="Field">Current Board:</div>
          <div className="Value">{this.props.info.rework}</div>
        </div>
        <div className="Fields">
          {
            this.state.reworkArray.map((w, index) =>
              <div key={"rework_" + index} >
                <div className="Field"/>
                <div className="Value2">
                  <input className="Checkbox" type="checkbox" value={index} checked={this.state.reworkArray[index] && true} onChange={this.setRework}/>{index + 1}
                </div>
                <div className="Value3">
                  {REWORK[index]}
                </div>
              </div>
            )
          }
        <div className="Field"/>
        <div className="Value3">
          <button className="ResetMCU" id='rework' value='rework' onClick={this.updateReader}>Set Rework</button>
        </div>
       </div>
     </div>
    );
  }
}

export default ProxyControlRework;

/*
<div className="Value2">
  <input className="Checkbox" type="checkbox" value="0" checked={this.state.reworkArray[0] && true} onChange={this.setRework}/>1
  <input className="Checkbox" type="checkbox" value="1" checked={this.state.reworkArray[1] && true} onChange={this.setRework}/>2
  <input className="Checkbox" type="checkbox" value="2" checked={this.state.reworkArray[2] && true} onChange={this.setRework}/>3
  <input className="Checkbox" type="checkbox" value="3" checked={this.state.reworkArray[3] && true} onChange={this.setRework}/>4
  <input className="Checkbox" type="checkbox" value="4" checked={this.state.reworkArray[4] && true} onChange={this.setRework}/>5
  <input className="Checkbox" type="checkbox" value="5" checked={this.state.reworkArray[5] && true} onChange={this.setRework}/>6
  <input className="Checkbox" type="checkbox" value="6" checked={this.state.reworkArray[6] && true} onChange={this.setRework}/>7
  <input className="Checkbox" type="checkbox" value="7" checked={this.state.reworkArray[7] && true} onChange={this.setRework}/>8
  <input className="Checkbox" type="checkbox" value="8" checked={this.state.reworkArray[8] && true} onChange={this.setRework}/>9
  <input className="Checkbox" type="checkbox" value="9" checked={this.state.reworkArray[9] && true} onChange={this.setRework}/>10
  <input className="Checkbox" type="checkbox" value="10" checked={this.state.reworkArray[10] && true} onChange={this.setRework}/>11
  <input className="Checkbox" type="checkbox" value="11" checked={this.state.reworkArray[11] && true} onChange={this.setRework}/>12
  <input className="Checkbox" type="checkbox" value="12" checked={this.state.reworkArray[12] && true} onChange={this.setRework}/>13
  <input className="Checkbox" type="checkbox" value="13" checked={this.state.reworkArray[13] && true} onChange={this.setRework}/>14
  <input className="Checkbox" type="checkbox" value="14" checked={this.state.reworkArray[14] && true} onChange={this.setRework}/>15
  <input className="Checkbox" type="checkbox" value="15" checked={this.state.reworkArray[15] && true} onChange={this.setRework}/>16
  <button id='rework' value='rework' onClick={this.updateReader}>Set Rework</button>
</div>
*/
