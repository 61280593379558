import React from 'react'

const GOODMAC = ["08:26:ae"];
const BADMAC = ["00:0a:35"];

class ProxyStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      macGreen: false,
      macBad: false,
    };
  }

  componentDidMount() {
    this.checkMac();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.info.somMac !== this.props.info.somMac) {
      this.checkMac();
    }
  }

  componentWillUnmount() {
  }

  checkMac() {
    this.setState({macGreen: false, macBad: false})

    if (this.props.info.somMac) {
       GOODMAC.forEach(item => {
         if (this.props.info.somMac.startsWith(item)) {
           this.setState({macGreen: true});
         }
       });

       BADMAC.forEach(item => {
         if (this.props.info.somMac.startsWith(item) ) {
           this.setState({macBad: true})
         }
       });
     }
   }

  //<div className="DetailField" style={{color: !this.props.compatible && "#E74C3C"}}>{this.props.info.mcuVer}</div>
  render() {
    return (
      <div className="Status">
        <div className="Columns">
            <div className="Column">
              Carrier
              <div className="DetailColumns">
                <div className="DetailColumn1">
                  <div className="DetailField">Board SNo:</div>
                  <div className="DetailField">Board Ver:</div>
                  <div className="DetailField">Board Rework:</div>
                  <div className="Break"/>
                  <div className="DetailField">MCU FW Ver:</div>
                  <div className="DetailField">MCU CID:</div>
                  <div className="Break"/>
                  <div className="DetailField">Power Carrier:</div>
                  <div className="DetailField">Power Module:</div>
                  <div className="DetailField">Power 9.5:</div>
                </div>
                <div className="DetailColumn2">
                  <div className="DetailField">{this.props.info.sno}</div>
                  <div className="DetailField">{this.props.info.ver}</div>
                  <div className="DetailField">{this.props.info.rework}</div>
                  <div className="Break"/>
                  <div className="DetailField">{this.props.info.mcuVer}</div>
                  <div className="DetailField">{this.props.info.mcuCid}</div>
                  <div className="Break"/>
                  <div className="DetailField">{this.props.info.carrier}</div>
                  <div className="DetailField">{this.props.info.module}</div>
                  <div className="DetailField">{this.props.info.power}</div>
                </div>
                <div className="DetailColumn1">
                  <div className="DetailField">Pressure:</div>
                  <div className="DetailField">Temp:</div>
                  <div className="DetailField">Orientation:</div>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="DetailField"> DAC Word:</div>
                  <div className="Break"/>
                  <div className="Break"/>
                </div>
                <div className="DetailColumn2">
                  <div className="DetailField">{this.props.info.pressure}</div>
                  <div className="DetailField">
                    {this.props.info.temp && (this.props.info.temp < 20 || this.props.info.temp > 60) && <div className="BadTemp">{this.props.info.temp} &#176;C</div>}
                    {this.props.info.temp && (this.props.info.temp >= 20 && this.props.info.temp <= 60) && <div>{this.props.info.temp} &#176;C</div>}
                  </div>
                  <div className="DetailField">{this.props.info.accel}
                    <div className="AccelDetails">{this.props.info.accelDetails.split(":")[0]}</div>
                    <div className="AccelDetails">{this.props.info.accelDetails.split(":")[1]}</div>
                    <div className="AccelDetails">{this.props.info.accelDetails.split(":")[2]}</div>
                  </div>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="DetailField">{this.props.info.dac}</div>
                  <div className="Break"/>
                  <div className="Break"/>
                </div>
              </div>
            </div>
            <div className="Column2">
              RFFE
              <div className="DetailColumns">
                <div className="DetailColumn1">
                  <div className="DetailField">Board Ver:</div>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                </div>
                <div className="DetailColumn2">
                  <div className="DetailField">{this.props.info.rffeBoard}</div>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="Break"/>
                </div>
              </div>
            </div>
            <div className="Column">
              SOM
              <div className="DetailColumns">
                <div className="DetailColumn1">
                  <div className="DetailField">OS:</div>
                  <div className="DetailField">Image Ver:</div>
                  <div className="Break"/>
                  <div className="DetailField"></div>
                  <div className="DetailField">SD Image:</div>
                  <div className="Break"/>
                  <div className="DetailField">FPGA Ver:</div>
                  <div className="DetailField">FPGA Date:</div>
                  <div className="Break"/>
                  <div className="DetailField">MAC Addr:</div>
                  <div className="DetailField">Zynq T:</div>
                  <div className="Break"/>
                </div>
                <div className="DetailColumn2">
                  <div className="DetailField DetailField2">{this.props.info.somOs}</div>
                  <div className="DetailField">{this.props.info.somImage}</div>

                  <div className="Break"/>
                  <div className="Break"/>
                  <div className="DetailField">{this.props.info.somDiskFree && (this.props.info.somDiskFree.split(/\s+/)[3]*1024/Math.pow(10,9)).toFixed(2) + " GB free"}</div>
                  <div className="Break"/>
                  <div className="DetailField">
                    {this.props.info.fpga['versioncode'] && this.props.info.fpga['versioncode'].replaceAll("0", "")}
                    {this.props.info.fpga['subversioncode'] && this.props.info.fpga['subversioncode'].replaceAll("0", "")}
                  </div>
                  <div className="DetailField">
                    {this.props.info.fpga['datecode'] && this.props.info.fpga['datecode'].split(":")[1].trim().split(/\s/).join(" ")}
                    {this.props.info.fpga['timecode'] && this.props.info.fpga['timecode'].split(":")[1].trim().split(/\s/).join(":").padStart(9, " ")}
                  </div>
                  <div className="Break"/>
                  <div className="DetailField" style={this.state.macBad ? {color: "#FF0000"} : {color: "#000"}}>
                    {this.props.info.somMac}
                    {this.state.macGreen && <img className="Green" src={require('./img/GreenCheck.png')} alt="Green Check" />}
                  </div>
                  <div className="DetailField">{this.props.info.somZ && <div>{this.props.info.somZ} &#176;C</div>}</div>
                  <div className="Break"/>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default ProxyStatus;

/*
const INFO = {
  host: "srdj22", ip: "192.168.2.124", proxyVer: "1.0.0", sno: "022", ver: "J01",
  rework: "0x0002", mcuVer: "1.0.0", mcuCid: "0xBF0A", carrier: "Good", module: "Good",
  power: "Good", pressure: "1008 kPa", accel: "Vertical", temp: "25.5 deg C", dac: "0x23ac",
  rffeBoard: "01", somOs: "Ubuntu 18.4", somMac: "12.22.bc.a2", somZ: "54.5 deg C"
}
*/
